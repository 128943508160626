exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assets-image-historical-indigenous-presence-gta-js": () => import("./../../../src/pages/assets/image-historical-indigenous-presence-gta.js" /* webpackChunkName: "component---src-pages-assets-image-historical-indigenous-presence-gta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-about-jumblies-by-these-presents-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/about/jumblies/by-these-presents.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-about-jumblies-by-these-presents-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-about-jumblies-dish-dances-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/about/jumblies/dish-dances.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-about-jumblies-dish-dances-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-about-jumblies-spectacle-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/about/jumblies/spectacle.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-about-jumblies-spectacle-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-about-talking-treaties-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/about/talking-treaties.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-about-talking-treaties-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-about-the-book-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/about/the-book.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-about-the-book-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-activities-dish-dances-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/activities/dish-dances.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-activities-dish-dances-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-activities-index-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/activities/index.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-activities-index-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-activities-land-back-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/activities/land-back.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-activities-land-back-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-activities-tkaronto-companion-guide-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/activities/tkaronto-companion-guide.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-activities-tkaronto-companion-guide-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-activities-today-future-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/activities/today-future.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-activities-today-future-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-additional-resources-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/additional-resources.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-additional-resources-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-bibliography-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/bibliography.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-bibliography-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-links-to-learn-more-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/links-to-learn-more.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-links-to-learn-more-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-peoples-of-this-land-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/peoples-of-this-land.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-peoples-of-this-land-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-polishing-the-chain-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/polishing-the-chain.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-polishing-the-chain-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-dish-with-one-spoon-first-treaty-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/dish-with-one-spoon/first-treaty.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-dish-with-one-spoon-first-treaty-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-dish-with-one-spoon-index-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/dish-with-one-spoon/index.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-dish-with-one-spoon-index-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-dish-with-one-spoon-peace-between-nations-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/dish-with-one-spoon/peace-between-nations.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-dish-with-one-spoon-peace-between-nations-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-index-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/index.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-index-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-toronto-purchase-1787-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/toronto-purchase/1787.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-toronto-purchase-1787-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-toronto-purchase-2010-settlement-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/toronto-purchase/2010-settlement.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-toronto-purchase-2010-settlement-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-toronto-purchase-confirmation-1805-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/toronto-purchase/confirmation-1805.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-toronto-purchase-confirmation-1805-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-treaty-of-niagara-1764-treaty-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/treaty-of-niagara/1764-treaty.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-treaty-of-niagara-1764-treaty-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-treaty-of-niagara-pontiac-war-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/treaty-of-niagara/pontiac-war.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-treaty-of-niagara-pontiac-war-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-two-row-wampum-covenant-chain-covenant-chain-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/two-row-wampum-covenant-chain/covenant-chain.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-two-row-wampum-covenant-chain-covenant-chain-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-two-row-wampum-covenant-chain-two-row-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/treaties-for-torontonians/two-row-wampum-covenant-chain/two-row.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-treaties-for-torontonians-two-row-wampum-covenant-chain-two-row-mdx" */),
  "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-what-is-a-treaty-mdx": () => import("./../../../src/templates/main-page-template.js?__contentFilePath=/opt/build/repo/src/mdx-pages/what-is-a-treaty.mdx" /* webpackChunkName: "component---src-templates-main-page-template-js-content-file-path-opt-build-repo-src-mdx-pages-what-is-a-treaty-mdx" */)
}

